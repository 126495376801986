import * as React from 'react'
import Layout from '../../../layout'
import { Bold, LegalContainer, LegalHeadline, LegalLabel, LegalText, LegalWrapper } from '../styles'


export default class ImprintPage extends React.Component {

  render() {
    return (
      <Layout location={'/legal/imprint'}>

        <LegalWrapper>
          <LegalContainer>
            <LegalHeadline>Impressum</LegalHeadline>
            <LegalLabel>Ing. Amann Verwaltungs GmbH</LegalLabel>
            <LegalText>Pfatterstr. 1<br/>93098 Mintraching</LegalText>
            <LegalText>Telefon (0 94 06) 94 03-0<br/> Telefax (0 94 06) 94 03-20</LegalText>
            <LegalText>Geschäftsführung: <br/>Axel Ginghuber</LegalText>
            <LegalText>Gesellschaftssitz:<br/>Pfatterstraße 1 <br/>93098 Tiefbrunn</LegalText>
            <LegalText>Registergericht Regensburg, HRB 1648<br/>USt-IdNr. DE133702871</LegalText>
            <LegalLabel>Streitschlichtung</LegalLabel>
            <LegalText>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung
              (OS)
              bereit: <a href="https://ec.europa.eu/consumers/odr"
                         target="_blank">https://ec.europa.eu/consumers/odr</a>. Unsere
              E-Mail-Adresse finden Sie oben im Impressum. Wir sind nicht bereit oder
              verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
              teilzunehmen.</LegalText>
            <LegalLabel>Haftungsausschluss</LegalLabel>
            <Bold>1. Inhalt des Onlineangebotes</Bold>
            <LegalText>Der Autor übernimmt
              keinerlei
              Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der
              bereitgestellten Informationen. Haftungsansprüche gegen den Autor, welche sich auf
              Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder
              Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und
              unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen,
              sofern seitens des Autors kein nachweislich vorsätzliches oder grob fahrlässiges
              Verschulden vorliegt. Alle Angebote sind freibleibend und unverbindlich. Der Autor
              behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne
              gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
              Veröffentlichung zeitweise oder endgültig einzustellen.</LegalText>
            <Bold>2. Verweise und Links</Bold>
            <LegalText>Trotz sorgfältiger inhaltlicher
              Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den
              Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber
              verantwortlich.</LegalText>
            <Bold>3. Urheber- und Kennzeichenrecht</Bold>
            <LegalText>Der Autor ist bestrebt,
              in
              allen Publikationen die Urheberrechte der verwendeten Grafiken, Tondokumente,
              Videosequenzen und Texte zu beachten, von ihm selbst erstellte Grafiken,
              Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken,
              Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle innerhalb des
              Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und
              Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen
              Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer.
              Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass
              Markenzeichen nicht durch Rechte Dritter geschützt sind! Das Copyright für
              veröffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim Autor der
              Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente,
              Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist
              ohne ausdrückliche Zustimmung des Autors nicht gestattet.</LegalText>
          </LegalContainer>
        </LegalWrapper>

      </Layout>
    )
  }
}
