import styled from 'styled-components'
import { Device } from '../../components/core/responsive'
import { Fonts } from '../../components/core/fonts'
import { Colors } from '../../components/core/colors'
import { Container } from '../../components/core/layout'

export const LegalWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 100px;
  margin-bottom: 48px;

  @media (${Device.laptop}){
    padding-top: 200px;
  }
`

export const LegalContainer = styled(Container)`
  flex-direction: column;
`


export const LegalHeadline = styled.h1`
    position: relative;
    font-weight: 900;
    font-size: 2rem;
    color: ${Colors.text};
    font-family: ${Fonts.default};
    font-stretch: condensed;
    margin-bottom: 24px;
    word-break: break-all;
`


export const LegalLabel = styled.h2`
  position: relative;
  font-weight: 900;
  font-size: 1.25rem;
  font-family: ${Fonts.default};
  color: ${Colors.text};
  font-stretch: condensed;
  margin-bottom: 12px;
`

export const LegalText = styled.p`
  margin-bottom: 18px;
  line-height: 1.5rem;
  color: ${Colors.text};
  word-break: normal;
`

export const Bold = styled.strong`
  font-weight: bold; 
`

export const LegalList = styled.li`
  margin-bottom: 18px;
  line-height: 1.5rem;
  color: ${Colors.text};
`

export const LegalLink = styled.a`
  word-break: break-all;
`

export const CompanyInfo = styled.p`
  margin-bottom: 6px;
`
